<template>
  <sm-shopping-layout>
    <div class="sm-product-screen sm-free-product-screen">
      <div class="sm-product-name-container">
        <h5 class="sm-product-name">{{ scannedProduct.name }}</h5>
      </div>

      <div class="sm-product-number-container">
        <span class="sm-product-number">Produkt-Nr.: {{ scannedProduct.productNo }}</span>
      </div>

      <div class="sm-product-description-container">
        <span v-html="scannedProduct.description ? scannedProduct.description.replace(/<[^>]*>/g, '') : '' " class="sm-product-description"></span>
      </div>

      <ion-item class="ion-margin-bottom" :class="{ 'has-warning': isWarning && errorMessage }">
        <ion-icon :icon="alertCircle" color="danger" slot="end"></ion-icon>

        <ion-label class="sm-ion-label" position="stacked">
          <div class="sm-input-label-container">
            <div class="sm-input-label">
              <span>Produktwunsch*</span>
            </div>

            <div class="sm-input-required">
              <span>*Pflichtfeld</span>
            </div>
          </div>
        </ion-label>

        <ion-textarea
          @change="onChangeMessage"
          :value="requestMessage"
          placeholder="Produktbeschreibung hier eingeben"
          :autoGrow="true"
          row="1"
          required
        ></ion-textarea>
      </ion-item>

      <div class="sm-error-message">
        <span v-if="errorMessage">{{ errorMessage }}</span>
      </div>
    </div>
  </sm-shopping-layout>
</template>

<script lang="javascript">
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { alertCircle } from 'ionicons/icons';
import { IonLabel, IonItem, IonTextarea, IonIcon} from "@ionic/vue";
import SmShoppingLayout from "@/components/layout/SmShoppingLayout";
import ScreenLoadingMixin from '@/mixins/screen-loading.mixin';
import CommonMixin from '@/mixins/common.mixin';
import ScannedProductMixin from '@/mixins/scanned-product.mixin';
import {useRouter} from "vue-router";
import EventBus from "@/services/event.service";

export default defineComponent({
  name: 'ScannedProduct',

  components: {
    SmShoppingLayout, IonLabel, IonItem, IonTextarea, IonIcon
  },

  mixins: [ScreenLoadingMixin, CommonMixin, ScannedProductMixin],

  setup() {
    const router = useRouter();
    return { router, alertCircle };
  },

  data () {
    return {
      errorMessage: '',
      isWarning: false,
      requestMessage: '',
    }
  },

  ionViewWillEnter() {
    this.setHeaderTitle('Free Artikel');
    EventBus().emitter.on('throwRequireFreeProductRequestMessage', this.onThrowRequireFreeProductRequestMessage);
  },

  mounted() {
    this.requestMessage = '';
    //TODO for testing
    // this.loadProductFromApi('bc176de77f9a4185bab9320fcf3f76cb');
  },

  computed: {
    ...mapGetters('product', ['getScannedProduct', 'getFreeProductRequest']),
  },

  methods: {
    ...mapMutations('common', ['setHeaderTitle']),
    ...mapMutations('product', ['setFreeProductRequest']),
    onThrowRequireFreeProductRequestMessage() {
      this.isWarning = true;
      this.errorMessage = 'Produktbeschreibung benötigt.';
    },

    onChangeMessage(val) {
      this.isWarning = false;
      this.errorMessage = '';
      this.setFreeProductRequest(val.target.value);
    }
  }
})
</script>

<style scoped>
  .sm-product-description-container .sm-product-description {
    font-size: 14px;
    font-weight: 400;
    color: var(--ion-color-secondary);
  }

  .sm-input-label-container {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .sm-ion-label {
    margin-right: 0;
  }

  .sm-input-label {
    text-transform: uppercase;
    font-weight: bold;
    color: var(--ion-color-dark);
  }

  .sm-input-required {
    color: var(--ion-color-secondary-tint);
  }

  .sc-ion-label-ios-h {
    transform: translateY(0) scale(1);
    margin-bottom: 0.5em;
    color: #4C5157;
  }

  .item-inner {
    position: relative;
    display: block;
  }

  ion-icon {
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 2;
    display: none;
  }

  .has-warning ion-icon {
    display: block;
  }

  .has-warning ion-textarea {
    padding-right: 30px !important;
    border-color: var(--ion-color-primary);
  }

  .sm-error-message {
    min-height: 1rem;
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    color: var(--ion-color-danger);
  }

  .ion-margin-bottom {
    margin-bottom: 0.5rem;
  }
</style>
